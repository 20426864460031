import React from "react"

import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"

import LogoImg from "../../../static/i/logo-center-licenziy.svg"

const FeedbackHeader = () => (
  <Container>
    <Nav defaultActiveKey="/home" className="d-block mx-auto w-100">
      <Navbar.Brand className="image-logo d-flex">
        <img
          src={LogoImg}
          alt="Логотип Центру Ліцензій"
          className="img-responsive"
        />
        <span className="logo feedback-logo">
          Центр Ліцензій
          <small className="slogan">Державні Ліцензії України</small>
        </span>
      </Navbar.Brand>
    </Nav>
  </Container>
)

export default FeedbackHeader
