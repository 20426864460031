import React, { useState } from "react"
import { Link } from "gatsby"

import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const FormFeedbackBad = () => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    setValidated(true)
  }
  return (
    <Form
      data-netlify="true"
      netlify-honeypot="bot-field"
      action="/feedback/done/"
      method="POST"
      name="feedbackform"
      form-name="feedbackform"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      id="feedbackForm"
    >
      <Form.Group hidden>
        <input type="hidden" name="form-name" value="feedbackform" />
        <Form.Label>
          Поле для незаповнення
          <Form.Control type="text" name="bot-field" />
        </Form.Label>
      </Form.Group>

      <Form.Group controlId="feedbackForm.ControlTextareaReport">
        <Form.Label>У чому виникла проблема?</Form.Label>
        <Form.Control name="feedback" as="textarea" rows={3} required />
        <Form.Control.Feedback type="invalid">
          опишіть, будь ласка, проблему
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="feedbackForm.ControlPhone">
        <Form.Label>Ваш номер телефону</Form.Label>
        <Form.Control type="tel" placeholder="+380" name="phone" required />
        <Form.Control.Feedback type="invalid">
          вкажіть номер, будь ласка
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="feedbackForm.ControlInputName">
        <Form.Label>Ваше ім'я</Form.Label>
        <Form.Control
          type="text"
          placeholder="як до Вас звернутися?"
          name="name"
        />
      </Form.Group>
      <Button
        variant="outline-success"
        type="submit"
        size="lg"
        className="feedback-bad"
        id="badFeedbackButton"
      >
        Надіслати
      </Button>
      <Link className="ml-5" to="/feedback/" id="feedbackBadButtonBack">
        повернутися
      </Link>
    </Form>
  )
}

export default FormFeedbackBad
