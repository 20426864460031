import React from "react"
import { graphql } from "gatsby"

import Container from "react-bootstrap/Container"

import SEO from "../../components/Global/MetaHead"
import FormFeedbackBad from "../../components/Global/FormFeedbackBad"
import FeedbackHeader from "../../components/Layout/feedback-header"

const FeedbackBadPage = ({ data }) => (
  <>
    <SEO
      title="Опишіть проблему"
      description="Будь ласка, напишіть, що пішло не так, і ми докладемо всіх зусиль, щоб виправити ситуацію і зробити сервіс краще."
      image={`https:${data.ogImage.fixed.src}`}
      url="/feedback/bad/"
      lang="uk"
      noindex="noindex"
    />
    <FeedbackHeader />
    <Container className="py-5" as="section">
      <h1 className="">
        Опишіть проблему
        <span role="img" aria-labelledby="sad">
          😟
        </span>
      </h1>
      <p className="pt-2 pb-2 ">
        Будь ласка, напишіть, що пішло не так, і ми докладемо всіх зусиль, щоб
        виправити ситуацію. Дякуємо.
      </p>
      <FormFeedbackBad />
    </Container>
  </>
)
export const query = graphql`
  {
    managers: allContentfulManager(filter: { node_locale: { eq: "uk" } }) {
      nodes {
        name
        id
      }
    }
    services: allContentfulService(filter: { node_locale: { eq: "uk" } }) {
      nodes {
        id
        title
        shortName
      }
    }
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      title
      id
      fixed(width: 620, height: 460) {
        src
      }
    }
  }
`

export default FeedbackBadPage
